<template>
    <div>
        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="pageLoaded">
                <template v-if="RFEMImportError">
                    <div class="card">
                        <div class="card-header light" style="color: red;">
                            {{ RFEMImportError }}
                        </div>
                    </div>
                </template>
                <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination pagination-always-visible no-state-loading autofocus :lines-per-page=data.crud_lines_per_page
                       :rows="processedEdges"
                       :fields="fields.table" :actions="actions.table" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:import-rfem-files="importRFEMFiles"
                       v-on:select-all="selectAll"
                       v-on:select-component="selectComponent"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>
            </div>

        </div>
    </div>
</template>

<script>
import Table from "@/components/Table";

/* global sketchup:false */

export default {
    name: 'RFEM',
    components: {
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    data() {
        return {
            pageLoaded: false,
            fields: null,
            actions: null,
            tableReload: 0,
            processedEdges: [],
            RFEMImportError: null,
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        fetchData() {
            this.$http.get(this.api + '/manage_rfem', {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.data = res.data.data;
                this.tableReload++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch((error) => {
                console.log("RFEM:fetchData():error:", error);
            });
        },
        getToolDataCallback(data) {
            this.fetchData(data.project_id);
        },
        importRFEMFiles(data) {
            this.processedEdges = [];
            this.RFEMImportError = null;
            this.state.loading = true;
            sketchup.importRFEMFiles((data['clear-model'] === '1'), this.data.componentLibraryLocation, this.data.dropBoxLocation);
        },
        importRFEMFilesCallback(data) {
            let response = JSON.parse(data);
            if (response.error) {
                this.RFEMImportError = response.error_message;
                this.state.loading = false;
                return;
            }

            this.$worker.run((rawEdges) => {
                let processedEdges = {
                    missingEdges: 0,
                    edges: [],
                }

                rawEdges.forEach(edge => {
                    if (!edge.entity_id) {
                        edge.hide_detail_actions = true;
                        edge.cross_section_style = "background: red; color: white;";
                        processedEdges.missingEdges++;
                    }

                    processedEdges.edges.push(edge);
                });

                return processedEdges;
            }, [response.edges]).then(data => {
                this.processedEdges = data.edges;
                if (data.missingEdges > 0) {
                    this.RFEMImportError = this.$i18n.t('translations.{number} Components could not be placed, because they were not found in the library', {number: data.missingEdges}) + "!";
                }
                this.tableReload++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch(error => {
                console.log("FREM:ImportRFEMFilesCallback():error:", error);
            });
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            if (component.entity_id) {
                sketchup.selectComponent(component.entity_id);
            }
        },
        zoomComponent(component) {
            if (component.entity_id) {
                sketchup.renderingOption('InactiveHidden', true);
                sketchup.renderingOption('InstanceHidden', true);
                sketchup.zoomExtents(component.entity_id);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>